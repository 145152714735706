<template>
  <v-container>
    <h1 class="text-h4">Jadwal Event</h1>

    <v-row v-if="!loading">
      <v-col xs="12" md="6" lg="4" v-for="event in parsedEvents" :key="event.name">
        <event-card :event="event" />
      </v-col>
    </v-row>
    <v-row justify="space-around" v-else>
      <v-progress-circular indeterminate color="primary" />
    </v-row>
  </v-container>
</template>

<script>
// import moment from 'moment'
import EventCardVue from '../components/Event/EventCard.vue'
import firebase from '@/plugins/firebase'

export default {
  name: 'EventViewer',

  components: {
    'event-card': EventCardVue
  },

  data () {
    return ({
      loading: false,
      events: []
    })
  },

  computed: {
    parsedEvents () {
      return this.$data.events.map(event => {
        event.startTime = event.startTime ? event.startTime.toDate() : null
        event.endTime = event.endTime ? event.endTime.toDate() : null

        return event
      })
    }
  },

  methods: {
    getSelectedEvents () {
      return new Promise((resolve, reject) => {
        var now = new Date()
        var resolution = []
        var eventCollection = firebase.firestore().collection('/events')
        var queries = [
          eventCollection.where('endTime', '>', now).get(),
          eventCollection.where('endTime', '==', null).where('startTime', '>=', now).get()
        ]

        Promise.all(queries).then(
          querySnapshots => {
            querySnapshots.forEach(querySnapshot => {
              querySnapshot.docs.forEach(doc => { resolution.push(Object.assign(doc.data(), { id: doc.id })) })
            })
            resolve(resolution)
          }
        ).catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    // console.log('Mounted successfully')
    this.$data.loading = true
    this.$data.events = await this.getSelectedEvents()
    this.$data.loading = false
  }
}
</script>
